/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:f2b68b76-10ca-4699-a5b1-79c926848bd5",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_WhMc3H24b",
    "aws_user_pools_web_client_id": "jfs8piv7rfhlu6t2cksr50v6u",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "neograzApi",
            "endpoint": "https://kvtplwc4ge.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
